import { Slot } from "@radix-ui/react-slot";
import clsx from "clsx";
import React from "react";
import { useFormControlContext } from "../../FormControlContext/FormControlContext";
import "./faItemContent.scss";

interface IFaItemContentProps extends React.ComponentPropsWithoutRef<typeof Slot> { }

export function FaItemContent(props: IFaItemContentProps) {
    const { disabled } = useFormControlContext();

    return (
        <Slot
            {...props}
            className={clsx(
                "fa-item-content",
                {
                    "fa-item-content--disabled": disabled,
                },
                props.className
            )}
        />
    );
}
