import React, { createContext, useContext, useId } from "react";

export type FormControlState = "normal" | "warning" | "error";

interface IFormControlContext {
    /**
     * ID of the input. Should be used to connect a <label> to a control like <input>, <select> etc
     */
    id: string;
    disabled: boolean;
    state: FormControlState;
}

const FormControlContext = createContext<IFormControlContext>({
    id: "",
    disabled: false,
    state: "normal",
});

export function useFormControlContext() {
    return useContext(FormControlContext);
}

interface IProvideFormControlProps {
    id?: string;
    disabled?: boolean;
    state?: FormControlState;

    children: React.ReactNode;
}
export function ProvideFormControl(props: IProvideFormControlProps) {
    const uncontrolledId = useId();

    return (
        <FormControlContext.Provider
            value={{
                id: props.id ?? uncontrolledId,
                disabled: props.disabled ?? false,
                state: props.state ?? "normal",
            }}>
            {props.children}
        </FormControlContext.Provider>
    );
}
