import clsx from "clsx";
import React from "react";
import { ValidationError } from "../../../util/customHooks/validation/useValidation";
import { computed } from "../../../util/functions";
import { FormControlState, ProvideFormControl } from "../../FormControlContext/FormControlContext";
import "./faItemRoot.scss";

interface IFaItemOwnRootProps {
    disabled?: boolean;
    state?: FormControlState;

    /**
     * If set overrides the `state` property
     */
    error?: ValidationError;
}
interface IFaItemRootProps
    extends IFaItemOwnRootProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof IFaItemOwnRootProps> { }

export function FaItemRoot(props: IFaItemRootProps) {
    const { disabled, state, error, onClick, ...restProps } = props;

    const computedState = computed((): FormControlState | undefined => {
        if (error) {
            return "error";
        }

        return state;
    });

    return (
        <ProvideFormControl disabled={disabled} state={computedState}>
            <div
                {...restProps}
                className={clsx(
                    "fa-item-root",
                    {
                        "fa-item-root--disabled": disabled,
                        "fa-item-root--clickable": onClick !== undefined,
                    },
                    restProps.className
                )}
                onClick={e => !disabled && onClick?.(e)}
            />
        </ProvideFormControl>
    );
}
